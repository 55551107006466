import Tooltip from '@material-ui/core/Tooltip';
import React from "react";
import {useTranslation} from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function ShareSnack(props) {
    const {t} = useTranslation();
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
  
    const handleClickOpenSuccess = () => {
        setOpenSuccess(true);
    };

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    /*const handleClickOpenError = () => {
        setOpenError(true);
    };*/
  
    const handleCloseError = () => {
        setOpenError(false);
    };

    const getShareContent = (source, shareLink, title, address, phone, whatsapp, sharePrice, shopName, formattedTime) => {
        var shareText2 = phone ? t('address') + ": " + address + "\n" + t("phoneNumber") + ": " + phone : t('address') + ": " + address ;
        if (whatsapp) {
            var shortWhatsapp = whatsapp.substring(whatsapp.indexOf("://")+3);
            if (shortWhatsapp.substring(shortWhatsapp.indexOf("wa.me/")+6).includes("/")) {
                shortWhatsapp = shortWhatsapp.substring(0, shortWhatsapp.lastIndexOf("/"));
            }
            shareText2 = phone ? `${t('address')}: ${address}\n${t("phoneNumber")}: ${phone}\nWhatsApp: ${shortWhatsapp}` : `${t('address')}: ${address}\nWhatsApp: ${shortWhatsapp}`;
        } 
        const shareContent = (source === "product") ? title + "\n" + sharePrice + "\n\n" + t("url") + ": " + shareLink + "\n\n" + shopName + "\n" + shareText2 : (source === "event") ? title + "\n" + formattedTime +"\n\n" + t("url") + ": " + shareLink + "\n" + shareText2 : title + "\n\n" + t("url") + ": " + shareLink + "\n" + shareText2;
        navigator.clipboard.writeText(shareContent);
        handleClickOpenSuccess();
    }
    
    return (
        <React.Fragment>
            <Tooltip title={props.source==="shop" ? t("copyShopInfo") : props.source==="merchant" ? t("copyMerchantInfo") : props.source==="event" ? t("copyEventInfo") : t("copyProductInfo")}>
            {props.source==="product" ? <div className="text-main-color copy-hint" style={{cursor: "pointer"}} onClick={()=>getShareContent(props.source, props.shareLink, props.title, props.address, props.phone, props.whatsapp, props.sharePrice, props.shopName)}>
              <span className="icon LuluHK copy_circle_line" style={{marginRight: "6px", fontSize: "28px"}}></span>
              <span style={{fontWeight: "bold"}}>{t('copyProductInfo')}</span>
              </div>
            : props.source==="event" ? <span className="icon LuluHK copy_circle_line" style={{cursor: "pointer", fontSize: "32.5px"}} onClick={()=>getShareContent(props.source, props.shareLink, props.title, props.address, undefined, undefined, undefined, undefined, props.formattedTime)}></span>
            : <span className="icon LuluHK copy_circle_line" style={{cursor: "pointer", fontSize: "32.5px"}} onClick={()=>getShareContent(props.source, props.shareLink, props.title, props.address, props.phone, props.whatsapp)}></span>}
            </Tooltip>
            {/*TODO can be simplifiedby passing severity variable*/}
            <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                {props.source==="shop" ? t("shopInfoCopiedSuccess") : props.source==="merchant" ? t("merchantInfoCopiedSuccess") : props.source==="event" ? t("eventInfoCopiedSuccess") : t("productInfoCopiedSuccess")}
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={3000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                {props.source==="shop" ? t("shopInfoCopiedFailure") : props.source==="merchant" ? t("merchantInfoCopiedFailure") : props.source==="event" ? t("eventInfoCopiedFailure") : t("productInfoCopiedFailure")}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
export default ShareSnack;
