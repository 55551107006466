import React from "react";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
//import { normalTheme } from '../../styles/theme'
import '../../font/demo.css';
import '../../font/iconfont.css';
import '../../styles/pretty-checkbox.css';
//import TimeDialogItem from "../TimeDialogItem";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from "react-i18next";

function closeMobilePlan(e){
    document.querySelector('.wrapper .content .content-small.width-small-2').classList.remove('show');
    document.querySelector('.wrapper .content .content-big.width-big-2').classList.remove('hide');
}
const ShoppingPlan = (props) => {
    const {t} = useTranslation();
    return (
    <React.Fragment>
        <div className="shopping">
            <div className="shopping-title">
                <span>{t("downloadOurApp")}</span>
                {/* { btn_manage }
                { btn_done } */}
            </div>
            
            <div className="mobile-nav">
                <div className="search-back">
                    <ArrowBackIosIcon onClick={closeMobilePlan}/>
                    <span className="text-main-color title">{t("downloadOurApp")}</span>
                </div>
                {/* { mobile_nav_right } */}
            </div>
            <div className="item-detail">
                <div style={{width: "100%"}}>
                    {props.children || //Default content: use our app dialog
                    <>
                    <img src="/logo_round200.png" alt="luluhk" style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "35px",
                    display: "block",
                    width: "92px",
                    }}/>
                    <p style={{fontSize: "15px", fontWeight: "bold"}}>{t("useOurApp1")}</p>
                    <p style={{fontSize: "14px", margin: "10px"}}>{t("useOurApp2")}</p>
                    <a href="#" target="_blank" rel="noreferrer"><img alt="apple" src={"../../img/"+t("appStoreIcon")} className="app-store-icon" style={{width: "7.3rem", height: "100%", marginTop: "1.8rem", marginLeft: "auto", marginRight: "auto",}}/></a>
                    <a href="#" target="_blank" rel="noreferrer"><img alt="google" src={"../../img/"+t("googlePlayIcon")} className="app-store-icon" style={{width: "7.3rem", height: "100%", marginTop: "1.3rem", marginLeft: "auto", marginRight: "auto",}}/></a>
                    <a href="#" target="_blank" rel="noreferrer"><img alt="huawei" src={"../../img/"+t("huaweiAppGalleryIcon")} className="app-store-icon" style={{width: "7.3rem", height: "100%", marginTop: "1.3rem", marginLeft: "auto", marginRight: "auto",}}/></a>
                    </>}
                </div>
            
            
            </div>
        </div>
    </React.Fragment>
    );
};

export default ShoppingPlan;