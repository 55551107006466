import { getShopList } from "../services/shopList";
import React from "react"
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../font/demo.css'
import '../font/iconfont.css'
import '../styles/shop.css'
import '../font/app_iconfont.js'
import Header from  "../components/item/Header"
import Page404 from "../pages/404";
import EmptyError from "../components/EmptyError";
import EmptyLoading from "../components/EmptyLoading";
import BoxItem from "../components/home/BoxItem"
import WarningDialog from "../components/WarningDialog";
import {shopStatusCode, getTimeRep, categoryToLevel, changeLanguage} from  "../utils/utils";

class ShopList extends React.Component{
    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            shopList: [],
            hasError: "",
            cartOpen: false,
            skip: 0,
            loading: false,
            hasMore: true,
            lat: null,
            lon: null,
        };
    }
    componentDidMount() { 
        const { skip, shopList} = this.state;
        const {i18n} = this.props;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const dLat = 22.288706;
        const dLon = 114.172773;
        let queryLat = dLat;
        let queryLon = dLon;

        if (params.get('lat') !== null && params.get('lat') !== "") {
            const lat = params.get('lat');
            const isNumber = /^\d+(\.\d+)?$/.test(lat);
            const formattedLat = parseFloat(lat).toFixed(6).replace(/\.?0*$/, '');
            const isLat = isNumber && Math.abs(Number(formattedLat)) <= 90;
            if (isLat) {
                queryLat = formattedLat
            }
        }

        if (params.get('lon') !== null && params.get('lon') !== "") {
            const lon = params.get('lon');
            const isNumber = /^\d+(\.\d+)?$/.test(lon);
            const formattedLon = parseFloat(lon).toFixed(6).replace(/\.?0*$/, '');
            const isLon = isNumber && Math.abs(Number(formattedLon)) <= 180;
            if (isLon) {
                queryLon = formattedLon
            }
        }

        const limit = 12;
        const queryLocale = params.get('locale') ? params.get('locale').toLowerCase() : "";
        var locale = "";

        if (queryLocale === "tc" || queryLocale === "zhhant" || queryLocale === "zh_hant" || queryLocale === "zh-hant" || queryLocale === "zhhk" || queryLocale === "zh_hk" || queryLocale === "zh-hk" || queryLocale === "zhtw" || queryLocale === "zh_tw" || queryLocale === "zh-tw" || queryLocale === "zhmo" || queryLocale === "zh_mo" || queryLocale === "zh-mo") {
            locale = "zh_Hant"
        } else if (queryLocale === "sc" || queryLocale === "zhhans" || queryLocale === "zh_hans" || queryLocale === "zh-hans" || queryLocale.slice(0,2) === "zh") {
            locale = "zh_Hans"
        } else if (queryLocale === "en" || queryLocale.slice(0,2) === "en") {
            locale = "en";
        }
        if (locale === "") {
            if(localStorage.getItem("language")){
                i18n.changeLanguage(localStorage.getItem("language"));
            }
        } else {
            i18n.changeLanguage(locale);
            localStorage.setItem("language",locale);
        }

        this.setState({ loading: true });

        let currentUrl = new URL(window.location.href);
        if (!currentUrl.searchParams.has('lat')) {
            currentUrl.searchParams.append('lat', dLat);
        } 
        if (!currentUrl.searchParams.has('lon')) {
            currentUrl.searchParams.append('lon', dLon);
        } 
        currentUrl.searchParams.set('lat', queryLat);
        currentUrl.searchParams.set('lon', queryLon);
        window.history.replaceState({}, '', currentUrl.href);

        getShopList(limit, skip, queryLat, queryLon).then((res)=>{
            if(res.data){
                const newShops = res.data.data;
                this.setState({
                    shopList: [...shopList, ...newShops],
                    skip: skip + limit,
                    hasMore: newShops.length === limit,
                    lat: queryLat,
                    lon: queryLon,
                });
            }else{
                this.setState({hasError: "getShopListError"});
            }
        }).catch((err)=>{
            getShopList(limit, skip, dLat, dLon).then((res)=>{
                if(res.data){
                    const newShops = res.data.data;
                    this.setState({
                        shopList: [...shopList, ...newShops],
                        skip: skip + limit,
                        hasMore: newShops.length === limit,
                        lat: dLat,
                        lon: dLon,
                    });
                }else{
                    this.setState({hasError: "getShopListError"});
                }
                
            }).catch((err)=>{
                this.setState({hasError: "netError"})
            }).finally(() => {
                this.setState({ loading: false });
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
        window.addEventListener("scroll", this.handleScroll, true);
    } 
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    loadMoreShops() {
        const { skip, shopList, lat, lon, loading, hasMore } = this.state;
        const limit = 12;
        if (loading || !hasMore) {
          return;
        }
    
        this.setState({ loading: true });

        getShopList(limit, skip, lat, lon).then((res)=>{
            if(res.data){
                const newShops = res.data.data;
                this.setState({
                    shopList: [...shopList, ...newShops],
                    skip: skip + limit,
                    hasMore: newShops.length === limit,
                });
            } else{
                this.setState({hasError: "getShopListError"});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError"})
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    
    reloadBody(){
        window.location.reload();
        /*const { skip, lat, lon } = this.state;
        this.setState({ loading: true });
        getShopList(skip, 0, lat, lon).then((res)=>{
            if(res.data){
                const newShops = res.data.data;
                this.setState({
                    shopList: newShops,
                });
            }else{
                this.setState({hasError: "getShopListError"});
            }
        }).catch((err)=>{
            this.setState({hasError: "netError"})
        }).finally(() => {
            this.setState({ loading: false });
        });*/
    }

    handleScroll = (e) =>  {
        const {loading, hasMore } = this.state;
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 60.0 ;
        if (bottom && !loading && hasMore) {
          this.loadMoreShops();
        }
    };
    
    goBack(){
        try {
            this.props.history.goBack();
        } catch (_) {
            window.location.replace("https://www.luluhk.com");
        }
    }

    render(){
        const {t, i18n} = this.props;
        const {shopList, hasError, cartOpen, loading, lat, lon} = this.state;

        const nextLanguage = () => {
            if(i18n.language==="en"){
                changeLanguage("zh_Hant");
                this.reloadBody();
            }else if(i18n.language==="zh_Hant"){
                changeLanguage("zh_Hans");
                this.reloadBody();
            }else{
                changeLanguage("en");
                this.reloadBody();
            }
        }

        if(shopList && !hasError){
            return (
                <React.Fragment>
                    {<Helmet>
                        <title>{`${t("parkingFacilities")} - Lulu HK`}</title>
                        <meta property="og:title" content={`Parking Facilities - Lulu HK`} />
                        <meta property="og:description" content={"香港 Hong Kong | 泊車指南 Parking Directory"} />
                        <meta property="og:image" content="/img/luluhk/luluhk_og.png"/>
                        <meta name="description" content={"路路香港 LULU HK | 香港泊車指南 A Parking Directory in Hong Kong"}/>
                    </Helmet>}
                    <div className="wrapper" id="page-top">
                        <Header location={false} merchant={false} reloadBody={()=>this.reloadBody()}></Header>
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                                <div className="mobile-icon-right">
                                <Link to="#">
                                <span id="mobile-lang-shoplist" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                                </Link>
                                <Link to="/setting">
                                    <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                                </Link>
                                <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={`https://www.luluhk.com/s?lat=${lat}&lon=${lon}`} open={ cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                                </div>
                            </div>
                        </div>
                        <div className="merchant-content" onScroll={this.handleScroll}>
                            <div id="tab-demo" className="tab-demo">
                                <ul className="tab-title">
                                    <li key="shop" className="active shoplist"><span data-href="tab-content" onClick={()=>document.getElementById("page-top").scrollIntoView({behavior:"smooth"})}>{t("parkingFacilities")}</span></li>
                                </ul>
                                <div id="Shop" className="tab-inner active">
                                {loading && shopList.length === 0 ? 
                                    <div className="no-shop" style={{ display: "block", marginTop: "5em" }}>
                                        <EmptyLoading msg={t("loading")}/>
                                    </div>
                                    : !loading && shopList.length === 0 ? 
                                        <div className="no-shop" style={{ display: "block", marginTop: "5em" }}>
                                            <span className="icon LuluHK No" style={{fontSize: "50px"}}></span>
                                            <p>{t("noShop")}</p>
                                        </div>
                                    : 
                                    <div className="tab-box">
                                        <div className="box-right">
                                            <div className="item-list">  
                                                {shopList.map((shop,index)=>{
                                                    let displayStatus, displayStatusMsg;
                                                    let shopResult = [];
                                                    shopResult = categoryToLevel(shop.categories);
                                                    var desc = ""
                                                    if (shopResult === undefined || shopResult.length === 0 || (shopResult.length === 1 && shopResult[0].trim() === '')) {
                                                        desc = "uncategorized"
                                                    } else {
                                                        for (var i = 0; i < shopResult.length; i++) {
                                                            if (i===3) {break}
                                                            var categoryDesc = (i === shopResult.length - 1 || i === 2) ? shopResult[i].split('>').pop().trim() : `${shopResult[i].split('>').pop().trim()}\xa0 • \xa0`
                                                            desc = desc + categoryDesc
                                                        }
                                                    }

                                                    switch(shop.status){
                                                        case shopStatusCode.active:
                                                            if(shop.isOpen){
                                                                displayStatus = "normal";
                                                            }else{
                                                                displayStatus = "photo";
                                                                if(shop.nextOpenTime){
                                                                    displayStatusMsg = getTimeRep(shop.nextOpenTime,"open");
                                                                }else{
                                                                    displayStatusMsg = t("shopClosedToday");
                                                                }
                                                            }
                                                            break;
                                                        case shopStatusCode.temporarilyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopTemporarilyClosed");
                                                            break;
                                                        case shopStatusCode.underRenovation:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopUnderRenovation");
                                                            break;
                                                        case shopStatusCode.permanentlyClosed:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopPermanentlyClosed");
                                                            break;
                                                        default:
                                                            displayStatus = "photo";
                                                            displayStatusMsg = t("shopClosed");
                                                            break;
                                                    }
                                                    return <BoxItem
                                                        key={shop.id}
                                                        handle={shop.handle}
                                                        status={displayStatus}
                                                        msg={displayStatus==="photo"?displayStatusMsg:null}
                                                        imgSrc={shop.cover}
                                                        imgAlt={shop.name}
                                                        name={shop.name}
                                                        desc={desc}
                                                        distance={shop.distance ? (shop.distance < 10 ? "<10m" : shop.distance < 1000 ? `${Math.trunc(shop.distance / 1)}m` : shop.distance < 1000000 ? `${Math.trunc(shop.distance / 1000)}.${Math.trunc(shop.distance / 100) % 10}km`: ">1000km") : null }
                                                    />  
                                                })}
                                                {loading && <div>{t("loading")}</div>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }else if(hasError){ //header + EmptyError
            if(hasError==="getShopListError"){
                return <Page404 />
            }else{
                return (
                    <React.Fragment>
                    <div className="wrapper">
                        <Header merchant={false} reloadBody={()=>this.reloadBody()}></Header>
                        <div className="mobile-merchant-main">
                            <div className="mobile-nav">
                                <div className="mobile-icon-right">
                                <Link to="#">
                                    <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                                </Link>
                                <Link to="/setting">
                                    <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                                </Link>
                                <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                                <WarningDialog source={"app"} shareLink={`https://www.luluhk.com/s?lat=${lat}&lon=${lon}`} open={ cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                                </div>
                            </div>
                        </div>
                        <div className="merchant-content">
                            <EmptyError msg={t("error")+": "+t(hasError)}/>
                        </div>
                    </div>
                    </React.Fragment>
                )
            }            
        }else{ //header + EmptyLoading
            return (
                <React.Fragment>
                <div className="wrapper">
                    <Header merchant={false} reloadBody={()=>this.reloadBody()}></Header>
                    <div className="mobile-merchant-main">
                        <div className="mobile-nav">
                            <div className="mobile-icon-right">
                            <Link to="#">
                                <span id="mobile-lang" className="text-main-color icon_pattern title" onClick={()=>nextLanguage()}>{t("currentLanguage")}</span>
                            </Link>
                            <Link to="/setting">
                                <span className="icon LuluHK settings_line icon_pattern text-main-color title"></span>
                            </Link>
                            <span className="text-main-color app" onClick={(e)=>{this.setState({cartOpen: true})}}>APP</span>
                            <WarningDialog source={"app"} shareLink={`https://www.luluhk.com/s?lat=${lat}&lon=${lon}`} open={ cartOpen } onClose={()=>{this.setState({cartOpen: false})}}></WarningDialog>
                            </div>
                        </div>
                    </div>
                    <div className="merchant-content">
                        <EmptyLoading msg={t("loading")}/>
                    </div>
                </div>
                </React.Fragment>
            )
        }

    }
}

export default withTranslation()(ShopList);