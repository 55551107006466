import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

// pages
//import Home from "./pages/Home";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import Setting from "./pages/Setting";
//import ShoppingCart from "./pages/ShoppingCart";
//import OrderPlan from "./pages/OrderPlan";
//import OrderDetail from "./pages/OrderDetail";
import Merchant from "./pages/Merchant";
//import Item from "./pages/Item";
import Page404 from "./pages/404";
import ShopList from "./pages/ShopList";
//TODO improve static web code
import UserAppRedirect from "./pages/StaticWeb/UserAppRedirect";
import UserAppWeb_en from "./pages/StaticWeb/UserAppWeb/UserAppWeb_en";
import UserAppWeb_tc from "./pages/StaticWeb/UserAppWeb/UserAppWeb_tc";
import UserAppWeb_sc from "./pages/StaticWeb/UserAppWeb/UserAppWeb_sc";
import TermsRedirect from "./pages/StaticWeb/TermsRedirect";
import TermsWeb_en from "./pages/StaticWeb/TermsWeb/TermsWeb_en";
import TermsWeb_tc from "./pages/StaticWeb/TermsWeb/TermsWeb_tc";
import TermsWeb_sc from "./pages/StaticWeb/TermsWeb/TermsWeb_sc";
import PrivacyRedirect from "./pages/StaticWeb/PrivacyRedirect";
import PrivacyWeb_en from "./pages/StaticWeb/PrivacyWeb/PrivacyWeb_en";
import PrivacyWeb_tc from "./pages/StaticWeb/PrivacyWeb/PrivacyWeb_tc";
import PrivacyWeb_sc from "./pages/StaticWeb/PrivacyWeb/PrivacyWeb_sc";

const AppRouter = () => (
  <Router>
    <Switch>
        {/*<Route path="/s/:shopHandle/:handle" component={Item} />*/}
        {/* <Route path="/order-plan" component={OrderPlan} /> */}
        {/* <Route path="/order-detail" component={OrderDetail} /> */}
        {/* <Route path="/shopping-cart" component={ShoppingCart} /> */}

        <Route path="/m/:username" component={Merchant} />
        <Route path="/s/:handle/:producthandle" component={Product} />
        <Route path="/s/:handle" component={Shop} />
        <Route path="/s" component={ShopList} />
        <Route path="/setting" component={Setting} />

        <Route path="/en/terms" component={TermsWeb_en} />
        <Route path="/tc/terms" component={TermsWeb_tc} />
        <Route path="/sc/terms" component={TermsWeb_sc} />

        <Route path="/en/privacy" component={PrivacyWeb_en} />
        <Route path="/tc/privacy" component={PrivacyWeb_tc} />
        <Route path="/sc/privacy" component={PrivacyWeb_sc} />

        <Route path="/en/user/app" component={UserAppWeb_en} />
        <Route path="/tc/user/app" component={UserAppWeb_tc} />
        <Route path="/sc/user/app" component={UserAppWeb_sc} />

        <Route exact path="/" component={UserAppRedirect} />
        <Route path="/user" component={UserAppRedirect} />
        <Route path="/user/app" component={UserAppRedirect} />
        <Route path="/app" component={UserAppRedirect} />
        <Route path="/en" component={UserAppRedirect} />
        <Route path="/tc" component={UserAppRedirect} />
        <Route path="/sc" component={UserAppRedirect} />

        <Route path="/terms" component={TermsRedirect} />
        <Route path="/privacy" component={PrivacyRedirect} />

        <Route path="*" component={Page404}/>
    </Switch>
  </Router>
);

export default AppRouter;