import Dialog from '@material-ui/core/Dialog';
import React from "react"
//import {useTranslation} from "react-i18next";

function Award(props) {
    //const {t, i18n} = useTranslation();
    const { onClose, open, name, description } = props;
    return (
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className="award-dialog icon-dialog">
        <div className="title text-main-color">{name}</div>
        <div className="award-dialog-content list-content">
            <div className="content">
                <div className="list">{ description }</div>
            </div>
        </div>  
      </Dialog>
    );
  }
function AwardDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
            <span style={{ cursor: "pointer" }} onClick={handleClickOpen}>{props.award.name}</span>
            <Award open={open} onClose={handleClose} name={props.award.name} description={props.award.description} />
        </React.Fragment>
    );
}
export default AwardDialog;
