import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import luluhk_cus from '../../../static-web-img/luluhk_cus.png';
import apple_tc from '../../../static-web-img/apple_tc.png';
import google_tc from '../../../static-web-img/google_tc.png';
import huawei_tc from '../../../static-web-img/huawei_tc.png';
import {changeLanguage} from "../../../utils/utils";

class UserAppWeb_tc extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.title ="路路香港 LULU HK";
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
    
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            const script3 = document.createElement("script");
        
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);

            const images = document.querySelectorAll('.app-store img');
            images.forEach(function(image) {
                image.addEventListener('click', function() {
                    alert('Coming Soon 敬請期待');
                });
            });
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/tc"><img draggable="false" src={luluhk} alt="luluhk"/></a>
            
            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/tc/user/app">App</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/s">泊車設施 (BETA)</a></li>
                </ul>
            </div>
        </nav>
        
        <div className="static-web-styling container">
            <div className="static-web-styling content text-center">
                <img className="static-web-styling logo-text img-fluid" draggable="false" src={luluhk_cus} alt="luluhk_customer"/>
                <div className="static-web-styling d-flex flex-column flex-sm-row justify-content-around">
                    <a href="#" target="_blank" rel="noreferrer"><img className="static-web-styling app-store img-fluid" draggable="false" src={apple_tc} alt="apple_download" title="Apple App Store"/></a>
                    <a href="#" target="_blank" rel="noreferrer"><img className="static-web-styling app-store img-fluid" draggable="false" src={google_tc} alt="google_download" title="Google Play"/></a>
                    <a href="#" target="_blank" rel="noreferrer"><img className="static-web-styling app-store img-fluid" draggable="false" src={huawei_tc} alt="huawei_download" title="Huawei AppGallery"/></a>  
                </div>
            </div>
        </div>

        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/en/user/app" lang="en" hrefLang="en" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/tc/user/app" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/sc/user/app" lang="zh-Hans" hrefLang="zh-Hans" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2024 路路香港科技有限公司</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default UserAppWeb_tc;
