
import Router from "./Router";
import React from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { normalTheme } from "./styles/theme";
import  DesktopStyle  from  "./styles/DesktopStyle";



// import './App.css';
// import useRWD from './useRWD';
// import { Router, Route,Switch } from "react-router-dom";
// import FirstPage from "./FirstPage";
// import SecondPage from "./SecondPage";


const App=()=>{
  return(
    <ThemeProvider theme={ normalTheme }>
      <DesktopStyle />
      <ToastContainer
        autoClose={2500}
        position="top-right"
        closeButton={false}
      />
      <Router />
    </ThemeProvider>
  );

}
// const App=()=>{
//     const device=useRWD();

//     if(device==="PC")
//       console.log("pc");
//     else if(device==="tablet")
//       console.log("tablet");
//     else
//       console.log("mobile");
    
//     return(
//       // <HashRouter> 
//         <Router>
//           <Route path="/" component={ FirstPage } />
//           <Route path="/second" component={SecondPage}/>
//         </Router>
//       // </HashRouter>
//     );
  
// }
export default App;
