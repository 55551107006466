import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class PrivacyWeb_en extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
    
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            const script3 = document.createElement("script");
        
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/en"><img draggable="false" src={luluhk} alt="luluhk"/></a>
            
            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/en/privacy">Privacy</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/en/terms">Terms</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>Privacy Policy</h1>
                <p>Version: 1.0 | Effective Date: 1-September-2024</p>
                <hr/>
                <p>Lulu HK Technologies Limited (中文：路路香港科技有限公司) and/or its subsidiary(ies) and/or affiliates (hereinafter collectively referred to as “Lulu HK”) pledges to meet fully with the requirements of the Personal Data (Privacy) Ordinance, Chapter 486 of the Laws of the Hong Kong Special Administrative Region (“Hong Kong”). In doing so, we will ensure compliance by our staff to the strictest standards of security and confidentiality in respect of all personal information and data (“Personal Data”) submitted by users via luluhk.com, its sub-domains, merchant service system, and any other websites, their sub-domains, media platforms, or applications including the Lulu HK mobile applications, developed in whole or in part by Lulu HK (hereinafter singly or collectively referred to as the “Channels”) and we will not, subject to the terms herein, release such Personal Data to anyone without prior consent of the relevant user(s) of the Channels (whether registered or not) (“User(s)”) except to the authorized persons listed under Section 4 headed “<strong>Disclosure or Transfer of Data</strong>”.</p>
                <p>We strongly recommend Users to read this <strong>Privacy Policy</strong> carefully to understand our policy and practices about the treatment of Personal Data provided by the Users on our Channels. This <strong>Privacy Policy</strong> is applicable to both registered and non-registered Users, and the provisions herein may be updated, revised, modified and/or amended from time to time as we deem necessary.</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>Definitions</h2>
                        <p>In this <strong>Privacy Policy</strong>, unless the context otherwise requires:</p>
                        <dl>
                            <div>
                                <dt>Channels</dt>
                                <dd>singly or collectively refers to luluhk.com, its sub-domains, merchant service system, and any other websites, their sub-domains, media platforms, or applications including the Lulu HK mobile applications, developed in whole or in part by Lulu HK;</dd>
                            </div>
                            <div>
                                <dt>Hong Kong</dt>
                                <dd>means the Hong Kong Special Administrative Region;</dd>
                            </div>
                            <div>
                                <dt>Lulu HK</dt>
                                <dd>collectively refers to Lulu HK Technologies Limited (中文：路路香港科技有限公司) and/or its subsidiary(ies) and/or affiliates;</dd>
                            </div>
                            <div>
                                <dt>Material(s)</dt>
                                <dd>refers to the content, merchant information, shop information, product information, photos, text, logos, images, graphics, links and/or any information and material on the Channels;</dd>
                            </div>
                            <div>
                                <dt>Member(s)</dt>
                                <dd>refers to the individual(s) or entity(ies) with a registered account on our Channels;</dd>
                            </div>
                            <div>
                                <dt>Merchant(s)</dt>
                                <dd>refers to any organization and its Related Parties using our Merchant Services;</dd>
                            </div>
                            <div>
                                <dt>Personal Data</dt>
                                <dd>refers to personal information and data;</dd>
                            </div>
                            <div>
                                <dt>Product(s)</dt>
                                <dd>means product(s) and service(s); and</dd>
                            </div>
                            <div>
                                <dt>User(s)</dt>
                                <dd>refers to the individual(s) or entity(ies) using our Channels, including non-registered user(s), registered user(s) and Merchant(s).</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Purpose of Collection of Personal Data</h2>
                        <p>When Users use our Channels, they may disclose or be asked to provide Personal Data. Although Users are not obligated to provide the Personal Data as requested on the Channels, Lulu HK may not be able to render certain services if Users fail to do so. Our purposes for collecting Personal Data on our Channels include but are not limited to the following:</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">for the daily operation of the services provided to Users;</li>
                            <li className="static-web-styling ol-li2-li">to verify the identity of Users and provide Users with various services offered by the Channels;</li>
                            <li className="static-web-styling ol-li2-li">to provide Users with a platform for browsing and sharing the Products uploaded by the Merchants;</li>
                            <li className="static-web-styling ol-li2-li">to identify Users who have posted Materials on the Channels;</li>
                            <li className="static-web-styling ol-li2-li">to identify Users who have viewed the Materials posted on the Channels;</li>
                            <li className="static-web-styling ol-li2-li">to provide Users with marketing and promotional materials;</li> 
                            <li className="static-web-styling ol-li2-li">to handle and follow up enquiries, reports, requests and complaints;</li>
                            <li className="static-web-styling ol-li2-li">to contact Users regarding administrative notices and communications relevant to the Users’ use of services offered by Lulu HK via our Channels;</li>
                            <li className="static-web-styling ol-li2-li">to design, develop and improve Products provided to Users;</li>
                            <li className="static-web-styling ol-li2-li">to compile and analyze aggregate statistics about the Users’ use of the Channels and services for our internal use;</li>
                            <li className="static-web-styling ol-li2-li">to enforce the <strong>Terms and Conditions</strong> and other rights of Lulu HK;</li>
                            <li className="static-web-styling ol-li2-li">to comply with legal obligations; and</li>
                            <li className="static-web-styling ol-li2-li">for any other purposes relating to the purposes stated above.</li>
                        </ol><br/>
                        <p>We endeavor to only collect Personal Data which we deem necessary and adequate but not excessive in relation to the purposes set out hereinabove.</p>
                        <p>If we require the use of Users’ Personal Data for a purpose other than those set out hereinabove, we will request the Users’ prescribed consent.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Collection of Personal Data</h2>
                        <p>We collect and store Personal Data about a User, including his/her/its name, username and encrypted password, address, email address, phone number, country/region, social media, and any other data voluntarily provided by the User. Occasionally, we may also collect additional Personal Data from a User in connection with surveys or promotions.</p>
                        <p>We automatically collect and store some information from the User’s computer or device in cookies and log files when a User accesses our Channels (Please refer to Section 5 headed “<strong>Cookies and Log Files</strong>” for details). We may also collect information about the activities conducted by a User via the Channels, such as content viewed, pages visited, searches and services used.</p>
                        <p>Only our duly authorized personnel will be permitted to access the Users’ Personal Data, and we shall not release such Personal Data to any third parties save and except for the circumstances listed under Section 4 headed “<strong>Disclosure or Transfer of Data</strong>”.</p>
                        <p>We do not collect and store any data that is marked as being locally saved on User's device.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Disclosure or Transfer of Data</h2>
                        <p>Generally, we only disclose and/or transfer Users’ Personal Data to our personnel for the purposes listed under Section 2 headed “<strong>Purpose of Collection of Personal Data</strong>”. However, we may disclose and/or transfer such data to third parties under the following circumstances:</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">where permitted under the <strong>Terms and Conditions</strong>;</li>
                            <li className="static-web-styling ol-li2-li">where the data are disclosed and/or transferred to any agents, third-party suppliers or external service providers who are under a duty of confidentiality and have been duly authorized by us to use such data to facilitate the services on the Channels;</li>
                            <li className="static-web-styling ol-li2-li">to regulatory, governmental or judicial authorities/bodies where we need to protect and defend our rights and property;</li>
                            <li className="static-web-styling ol-li2-li">to regulatory, governmental or judicial authorities/bodies where we need to comply with applicable laws and regulations, including but not limited to compliance with a judicial proceeding, court order, or legal process;</li>
                            <li className="static-web-styling ol-li2-li">where we deem necessary in order to deliver the Products that Lulu HK offers, to maintain and improve the services on our Channels; and</li>
                            <li className="static-web-styling ol-li2-li">when we sell, transfer, divest, or disclose all or a portion of our business or assets to another company in connection with or during negotiation of any merger, financing, acquisition, dissolution, transaction, proceeding, insolvency, bankruptcy or receivership. Such third party will have to comply with appropriate confidentiality and security measures.</li>
                        </ol><br/>
                        <p>Personal Data collected via the Channels may be transferred, stored and processed in any country/region in which Lulu HK operates. By using our Channels, User is deemed to have consented to and authorized us to disclose and/or transfer his/her/its Personal Data under the circumstances stated above, as well as to transfer his/her/its data (including the Materials) outside of the User’s country.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Cookies and Log Files</h2>
                        <p>Cookies are small text files containing a string of characters that can be placed on a User’s computer or mobile device that uniquely identifies a User’s browser or device.</p>
                        <p>When a User accesses our Channels, we automatically record and store information, including the domain name server address and the pages the User visits, in cookies. We also automatically gather and store information, including Internet Protocol addresses, browser type, Internet Service Provider, referring/exit pages, operating system, date/time stamp, and clickstream data, in log files. We do not link these automatically-collected data to any personally identifiable information of a User.</p>
                        <p>We generally use cookies and log files to understand how the Channels are being used, estimate the audience size of the Channels and promotion materials, help Users navigate between pages efficiently, help remember Users’ preferences, and improve Users’ experience in using our Channels and services. Lulu HK, third-party vendors and service providers engaged by Lulu HK may use Lulu HK’s cookies and third-party cookies together to optimize and make the marketing materials Users see online more relevant to Users’ interests.</p>
                        <p>User can choose to accept or decline cookies. Most web browsers automatically accept cookies, but User can modify the browser settings to decline cookies if preferred. However, User should note that disabling cookies on the browser may prevent him/her/it from fully experiencing the features of our Channels.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Links to third-party Websites / Media Platforms / Applications</h2>
                        <p>Our Channels may provide links to third-party websites/media platforms/applications which are not owned or managed by us. Where and when a User decides to click on any link on the Channels which grants User access to a third-party website/media platform/application, his/her/its Personal Data may be exposed and collected by such third-party website/media platform/application.</p>
                        <p>Users are reminded that such third-party websites/media platforms/applications follow separate and independent privacy policies, and this <strong>Privacy Policy</strong> grants no protection to Users’ Personal Data that may be exposed on such third-party websites/media platforms/applications. We assume no responsibility or liability for Users’ use of such third-party websites/media platforms/applications.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Security</h2>
                        <p>We follow the generally accepted industry standards and implemented adequate electronic and managerial measures to protect the Personal Data submitted by Users from unauthorized access, accidental loss or destruction.</p>
                        <p>All Personal Data provided by Users are only accessible by the authorized personnel of Lulu HK or Lulu HK’s authorized third parties, and such personnel shall be instructed to observe the terms of this <strong>Privacy Policy</strong> when accessing such Personal Data.</p>
                        <p>Registered User is responsible for keeping his/her/its password secure and confidential.</p>
                        <p>While we endeavor to protect User’s Personal Data, no method of transmission over the Internet and electronic storage are completely secure, so we cannot guarantee its absolute security.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Request for Data Access and Rectification</h2>
                        <p>User may request access to or rectify his/her/its Personal Data retained by Lulu HK to keep such information accurate. This can be made by logging in to the account maintenance page of the Channels to view, edit or amend his/her/its stored Personal Data, or by contacting our Customer Service Representative through the ways listed under Section 12 headed “<strong>Enquiries</strong>”.</p>
                        <p>If a User wishes to access or amend his/her/its Personal Data, we may request him/her/it to provide personal details in order to verify his/her/its identity. Some information, including but not limited to business registration certificate number, cannot be amended unless they are proved to be inaccurate.</p>
                        <p>Subject to relevant laws, we have the right to charge User a reasonable fee for such request.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Retention of Data</h2>
                        <p>Once we have obtained the Personal Data from the Users, it will be maintained securely in our system to fulfill the purposes listed under Section 2 headed “<strong>Purpose of Collection of Personal Data</strong>”. Subject to legal requirements, the Personal Data will be retained by Lulu HK, unless the User terminates his/her/its membership of the Channels by contacting our Customer Service Representative through the ways listed under Section 12 headed “<strong>Enquiries</strong>”. However, we have the right to keep such data if we need such data to comply with legal obligation and/or to establish, exercise, or defend legal claims.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Changes to this Privacy Policy</h2>
                        <p>We reserve the right to update, revise, modify or amend this <strong>Privacy Policy</strong> at any time as we deem necessary. We strongly recommend Users to review this <strong>Privacy Policy</strong> periodically. If we decide to make changes to this <strong>Privacy Policy</strong>, we will post the revised <strong>Privacy Policy</strong> on this webpage and/or other places we deem appropriate. If we make material changes to this <strong>Privacy Policy</strong>, we will notify Users on Lulu HK’s website, mobile applications, and/or by email.</p>
                        <p>Any such changes will become effective immediately upon posting of the revised <strong>Privacy Policy</strong> on this webpage. User’s continued use of Lulu HK’s Channels and services following such changes constitutes acceptance of the revised <strong>Privacy Policy</strong> then in effect.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Prevailing Language</h2>
                        <p>If there is any inconsistency or ambiguity between the English version and any version in other languages, the English version shall prevail. </p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Enquiries</h2>
                        <p>Should you have any enquiries about this <strong>Privacy Policy</strong>, please contact our Customer Service Representative through the following ways:</p>
                        <address><strong>Lulu HK Technologies Limited</strong><br/>
                        Email Address: <a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>
        
        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/en/privacy" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/tc/privacy" lang="zh-Hant" hrefLang="zh-Hant" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/sc/privacy" lang="zh-Hans" hrefLang="zh-Hans" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2024 Lulu HK Technologies Limited</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default PrivacyWeb_en;
