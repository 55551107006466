import React, {Component} from 'react';
import  { Redirect } from 'react-router-dom'

class TermsRedirect extends Component {
    render (){
        var link
        if (localStorage.getItem("language")==="en") {link = "/en/terms"}
        if (localStorage.getItem("language")==="zh_Hant") {link = "/tc/terms"}
        if (localStorage.getItem("language")==="zh_Hans") {link = "/sc/terms"}
        if (localStorage.getItem("language")==null || localStorage.getItem("language")==="") {link = "/en/terms"}
        return (<Redirect to={link}/>)
    }
}

export default TermsRedirect;
