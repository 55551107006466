import React from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const AboutLuluHK = (props) => {
    const {t} = useTranslation();
    const {termsWebsiteLanguage} = props;
    return (
        <div className="about-luluhk-content sub-content" id="about-luluhk">
            <div className="about-data" style={{cursor: "pointer"}} onClick={(e)=>{window.open("https://luluhk.com/"+termsWebsiteLanguage+"/terms/").focus()}}>
                <span>{t("ui007024TermsOfUse")}</span>
                <ArrowForwardIosIcon />
            </div>
            <div className="about-data" style={{cursor: "pointer"}} onClick={(e)=>{window.open("https://luluhk.com/"+termsWebsiteLanguage+"/privacy/").focus()}}>
                <span>{t("ui007024PrivacyPolicy")}</span>
                <ArrowForwardIosIcon />
            </div>
            <hr/>
            <div className="about-data">
                <span style={{fontWeight: "bold"}}>{t("downloadOurApp")}</span>
            </div>
            <div className="app-href">
                <a href="#" target="_blank" rel="noreferrer"><img src={"../../img/"+t("appStoreIcon")} alt="app-store"></img></a>
                <a href="#" target="_blank" rel="noreferrer"><img src={"../../img/"+t("googlePlayIcon")} alt="play-store"></img></a>
                <a href="#" target="_blank" rel="noreferrer"><img src={"../../img/"+t("huaweiAppGalleryIcon")} alt="app-gallery"></img></a>
            </div>
        </div>
    )
}
export default AboutLuluHK;  